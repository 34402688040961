import {on} from "./utils";

const SwitchImagesOnHover = () => {

  const $elements = document.querySelectorAll("[data-target][data-src]");
  const $placeholder = document.querySelector(".img-placeholder");
  const placeholder_img = $placeholder.getAttribute("src");

  const _show = $el => {
    let $target = $placeholder;
    if($target === $placeholder){}
    if($el.getAttribute("data-target") !== "img-placeholder"){
      $target = document.querySelector($el.getAttribute("data-target"));
    }
    $target.setAttribute("src", $el.getAttribute("data-src"));
  }

  const _hide = () => {
    $placeholder.setAttribute("src", placeholder_img);
  }

  $elements.forEach($el => {
    on($el, "mouseenter", e => _show($el));
    on($el, "click", e => _show($el));
    on($el, "mouseleave", e => _hide());
  });

}


SwitchImagesOnHover();