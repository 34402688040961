//source: https://morioh.com/p/5b34d9858cb5
//source: https://blog.madza.dev/24-modern-es6-code-snippets-to-solve-practical-js-problems

/**
 * Get closest element
 * @param el {HTMLElement}
 * @param selector {String}
 * @returns {HTMLElement|null}
 */
const closest = (el, selector) => {
  let matchesFn;

  // find vendor prefix
  ['matches','webkitMatchesSelector','mozMatchesSelector','msMatchesSelector','oMatchesSelector'].some(fn => {
    if (typeof document.body[fn] === 'function') { matchesFn = fn; return true; }
    return false;
  })

  let parent;

  // traverse parents
  while (el) {
    parent = el.parentElement;
    if (parent && parent[matchesFn](selector)) { return parent; }
    el = parent;
  }

  return null;
}

/**
 * Is device in portrait mode?
 * @returns {boolean}
 */
const isPortrait = () => window.matchMedia("(orientation: portrait)").matches;

/**
 * Is device in landscape mode?
 * @returns {boolean}
 */
const isLandscape = () => !isPortrait()

/**
 * Get element siblings
 * @param el {HTMLElement}
 * @returns {[]}
 */
const siblings = el => {
  const siblings = [];
  let sibling = el.parentNode.firstChild;

  while(sibling){
    if(sibling.nodeType === 1 && sibling !== el){ siblings.push(sibling); }
    sibling = sibling.nextSibling
  }

  return siblings;
};

/**
 * Toggle classes
 * @param el {HTMLElement}
 * @param className {String}
 * @returns {*}
 */
const toggleClasses = (el, className = []) => className.map(cl => toggleClass(el, cl));

/**
 * Hide all elements
 * @param el {HTMLElement}
 */
const hide = (...el) => [...el].forEach(e => (e.style.display = 'none'));

/**
 * Element has class
 * @param el {HTMLElement}
 * @param className {String}
 * @returns {boolean}
 */
const hasClass = (el, className) => el.classList.contains(className);

/**
 * Toggle class
 * @param el {HTMLElement}
 * @param className {String}
 * @returns {boolean}
 */
const toggleClass = (el, className) => el.classList.toggle(className);

/**
 * Add classes to element
 * @param el {HTMLElement}
 * @param classNames {(String|Array)}
 */
const addClasses = (el, classNames) => {
  if(isString(classNames)){ classNames = classNames.split(" "); }
  classNames.forEach(className => el.classList.add(className));
}

/**
 * Get current scroll position
 * @param el {HTMLElement}
 * @returns {{x: (number|string), y: (number|string)}}
 */
const scrollPosition = (el = window) => ({
  x: el.pageXOffset !== undefined ? el.pageXOffset : el.scrollLeft,
  y: el.pageYOffset !== undefined ? el.pageYOffset : el.scrollTop
});

/**
 * Scroll to top
 */
const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};

/**
 * Scroll to element
 * @param el {HTMLElement}
 * @param opts {Object}
 */
const scrollToElement = (el, opts = {behavior: "smooth"}) => scrollToY(el.getBoundingClientRect().top, opts);

/**
 * Scroll to element
 * @param y {Number}
 * @param opts {Object}
 */
const scrollToY = (y, opts = {behavior: "smooth"}) => window.scrollTo({...opts, top: y});


/**
 * This snippet checks whether the parent element contains the child.
 * @param parent {HTMLElement}
 * @param child {HTMLElement}
 * @returns {boolean|*|jQuery|boolean}
 */
const elementContains = (parent, child) => parent !== child && parent.contains(child);

/**
 * Is element visible in viewport?
 * @param el {HTMLElement}
 * @param partiallyVisible
 * @returns {boolean|boolean}
 */
const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
  const { top, left, bottom, right } = el.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;
  return partiallyVisible
    ? ((top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight)) &&
    ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

function isAnyPartOfElementInViewport(el) {
  const rect = el.getBoundingClientRect();
  // DOMRect { x: 8, y: 8, width: 100, height: 100, top: 8, right: 108, bottom: 108, left: 8 }
  const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
  const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

  // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
  const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
  const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

  return (vertInView && horInView);
}

/**
 * Get Images
 * @param el {HTMLElement}
 * @param includeDuplicates
 * @returns {string[]}
 */
const getImages = (el, includeDuplicates = false) => {
  const images = [...el.getElementsByTagName('img')].map(img => img.getAttribute('src'));
  return includeDuplicates ? images : [...new Set(images)];
};

const nextAll = element => {
  const nextElements = []
  let nextElement = element

  while(nextElement.nextElementSibling) {
    nextElements.push(nextElement.nextElementSibling)
    nextElement = nextElement.nextElementSibling
  }

  return nextElements
}

/**
 * Get device type
 * @returns {string}
 */
const detectDeviceType = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop';

/**
 * Is device a Mobile?
 * @returns {boolean}
 */
const deviceIsMobile = () => detectDeviceType() === 'Mobile';

/**
 * Is device a Desktop?
 * @returns {boolean}
 */
const deviceIsDesktop = () => detectDeviceType() === 'Desktop';

/**
 * Get current URL
 * @returns {*}
 */
const currentURL = () => window.location.href;

/**
 * Convert form to array
 * @param form {HTMLFormElement}
 * @returns {Object}
 */
const formToObject = form =>
  Array.from(new FormData(form)).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value
    }),
    {}
  );

/**
 * Delay function
 * @param fn {Function}
 * @param wait {Number}
 * @param args {Object}
 * @returns {number}
 */
const delay = (fn, wait, ...args) => setTimeout(fn, wait, ...args);

/**
 * Run promises in serie
 * @param ps {Array}
 * @returns {*}
 */
const runPromisesInSeries = ps => ps.reduce((p, next) => p.then(next), Promise.resolve());

/**
 * Trigger event
 * @param el {HTMLElement}
 * @param eventType
 * @param customData
 * @returns {boolean}
 */
const triggerEvent = (el, eventType, customData) => el.dispatchEvent(new CustomEvent(eventType, { detail: customData }));

/**
 * Add event
 * @param el {HTMLElement}
 * @param evt {String}
 * @param fn {Function}
 * @param opts {Object}
 * @returns {*}
 */
const on = (el, evt, fn, opts = false) => el.addEventListener(evt, fn, opts);

/**
 * Remove event
 * @param el {HTMLElement}
 * @param evt {String}
 * @param fn {Function}
 * @param opts {Object}
 * @returns {*}
 */
const off = (el, evt, fn, opts = false) => el.removeEventListener(evt, fn, opts);

/**
 * Copy text to clipboard
 * @param str {String}
 */
const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  const selected =
    document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};

/**
 * Is tab focused?
 * @returns {boolean}
 */
const isBrowserTabFocused = () => !document.hidden;

/**
 * This snippet returns true if the predicate function returns true for all elements in a collection and false otherwise
 * @param arr {Array}
 * @param fn {Function}
 * @returns {*}
 */
const all = (arr, fn = Boolean) => arr.every(fn);

/**
 * This snippet converts the elements that don’t have commas or double quotes to strings with comma-separated values.
 * @param arr {Array}
 * @param delimiter {String}
 * @returns {*}
 */
const arrToCSV = (arr, delimiter = ',') => arr.map(v => v.map(x => `"${x}"`).join(delimiter)).join('\n');

/**
 * This snippet converts the elements of an array into `` tags and appends them to the list of the given ID.
 * @param arr {Array}
 * @param listID {String}
 * @returns {*}
 */
const arrayToHtmlList = (arr, listID) =>
  (el => (
    (el = document.querySelector('#' + listID)),
      (el.innerHTML += arr.map(item => `<li>${item}</li>`).join(''))
  ))();

const arrayToUnorderedListString = arr => {
  const ul = document.createElement('ul');

  arr.forEach(name => {
    const li = document.createElement('li');
    ul.appendChild(li);
    li.innerHTML = name;
  });

  return ul.outerHTML;
};

/**
 * This snippet checks whether the bottom of a page is visible.
 * @returns {boolean}
 */
const bottomVisible = () =>
  document.documentElement.clientHeight + window.scrollY >=
  (document.documentElement.scrollHeight || document.documentElement.clientHeight);

/**
 * String to Camel Case
 * @param str {String}
 * @returns {String}
 */
const camelCase = str => str.replace(/_([a-z])/g, g => g[1].toUpperCase());

/**
 * This snippet capitalizes the first letter of a string.
 * @param first {String}
 * @param rest
 * @returns {string}
 */
const capitalize = ([first, ...rest]) => first.toUpperCase() + rest.join('');

/**
 * This snippet capitalizes the first letter of every word in a given string.
 * @param str {String}
 * @returns {String}
 */
const capitalizeEveryWord = str => str.replace(/\b[a-z]/g, char => char.toUpperCase());

/**
 * This snippet removes false values from an array.
 * @param arr {Array}
 * @returns {Array}
 */
const compact = arr => arr.filter(Boolean);

/**
 * This snippet counts the occurrences of a value in an array.
 * @param arr {Array}
 * @param val {*}
 * @returns {Number}
 */
const countOccurrences = (arr, val) => arr.reduce((a, v) => (v === val ? a + 1 : a), 0);

/**
 * This snippet flattens an array recursively.
 * @param arr {Array}
 * @returns {*[]}
 */
const deepFlatten = arr => [].concat(...arr.map(v => (Array.isArray(v) ? deepFlatten(v) : v)));

/**
 * This snippet delays the execution of a function until the current call stack is cleared.
 * @param fn {Function}
 * @param args {Object}
 * @returns {number}
 */
const defer = (fn, ...args) => delay(fn, 1, args);

/**
 * This snippet finds the difference between two arrays.
 * @param a {Array}
 * @param b {Array}
 * @returns {*}
 */
const difference = (a, b) => {
  const s = new Set(b);
  return a.filter(x => !s.has(x));
};

/**
 * This snippet returns a new array with n elements removed from the left.
 * @param arr {Array}
 * @param n {Number}
 * @returns {Array}
 */
const drop = (arr, n = 1) => arr.slice(n);

/**
 * This snippet returns a new array with n elements removed from the right.
 * @param arr {Array}
 * @param n {Number}
 * @returns {Array}
 */
const dropRight = (arr, n = 1) => arr.slice(0, -n);

/**
 * This snippet removes elements from the right side of an array until the passed function returns true.
 * @param arr {Array}
 * @param fn {Function}
 * @returns {Array}
 */
const dropRightWhile = (arr, fn) => {
  while (arr.length > 0 && !fn(arr[arr.length - 1])) arr = arr.slice(0, -1);
  return arr;
};

/**
 * This snippet removes elements from an array until the passed function returns true.
 * @param arr {Array}
 * @param fn {Function}
 * @returns {Array}
 */
const dropWhile = (arr, fn) => {
  while (arr.length > 0 && !fn(arr[0])) arr = arr.slice(1);
  return arr;
};

/**
 * This snippet removes duplicate values in an array.
 * @param arr {Array}
 * @returns {any[]}
 */
const filterNonUnique = arr => [...new Set(arr)];

/**
 * This snippet returns the first key that satisfies a given function.
 * @param obj {Object}
 * @param fn {Function}
 * @returns {string}
 */
const findKey = (obj, fn) => Object.keys(obj).find(key => fn(obj[key], key, obj));

/**
 * This snippet returns the last element for which a given function returns a truthy value.
 * @param arr {Array}
 * @param fn {Function}
 * @returns {any}
 */
const findLast = (arr, fn) => arr.filter(fn).pop();

/**
 * This snippet can be used to get the value of a CSS rule for a particular element.
 * @param el {HTMLElement}
 * @param property {String}
 * @returns {string}
 */
const getStyle = (el, property) => getComputedStyle(el).getPropertyValue(property);

/**
 * This snippet can be used to insert an HTML string after the end of a particular element.
 * @param el {HTMLElement}
 * @param htmlString {String}
 */
const insertAfter = (el, htmlString) => el.insertAdjacentHTML('afterend', htmlString);

/**
 * This snippet can be used to insert an HTML string before a particular element.
 * @param el {HTMLElement}
 * @param htmlString {String}
 */
const insertBefore = (el, htmlString) => el.insertAdjacentHTML('beforebegin', htmlString);

/**
 * This snippet can be used to get an array with elements that are included in two other arrays.
 * @param a {Array}
 * @param b {Array}
 * @returns {Array}
 */
const intersection = (a, b) => {
  const s = new Set(b);
  return a.filter(x => s.has(x));
};

/**
 * This snippet can be used to check if a value is of a particular type.
 * @param type
 * @param val
 * @returns {boolean|boolean}
 */
const is = (type, val) => ![, null].includes(val) && val.constructor === type;

/**
 * This snippet can be used to check whether an argument is a boolean.
 * @param val
 * @returns {boolean}
 */
const isBoolean = val => typeof val === 'boolean';

/**
 * This snippet can be used to check whether a value is null or undefined.
 * @param val
 * @returns {boolean}
 */
const isNil = val => val === undefined || val === null;

/**
 * This snippet can be used to check whether a value is null or undefined.
 * @param val
 * @returns {boolean}
 */
const isNilOrEmpty = val => isNil(val) || val.trim() === "";

/**
 * This snippet can be used to check whether a provided value is a number.
 * @param n
 * @returns {boolean}
 */
const isNumber = n  => !isNaN(parseFloat(n)) && isFinite(n);

/**
 * This snippet can be used to check whether an argument is a string.
 * @param val
 * @returns {boolean}
 */
const isString = val => typeof val === 'string';

/**
 * This snippet can be used to check whether a string is a valid JSON.
 * @param str
 * @returns {boolean}
 */
const isValidJSON = str => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * This snippet returns the first element of an array
 * @param arr
 * @returns {*}
 */
const first = arr => arr[0];

/**
 * This snippet returns the last element of an array
 * @param arr
 * @returns {*}
 */
const last = arr => arr[arr.length - 1];

/**
 * This snippet can be used to convert a nodeList to an array.
 * @param nodeList
 * @returns {*[]}
 */
const nodeListToArray = nodeList => [...nodeList];

/**
 * This snippet can be used to generate a random integer in a specified range.
 * @param min
 * @param max
 * @returns {*}
 */
const randomIntegerInRange = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

/**
 * This snippet can be used to do a redirect to a specified URL.
 * @param url
 * @param asLink
 * @returns {*}
 */
const redirect = (url, asLink = true) => asLink ? (window.location.href = url) : window.location.replace(url);

/**
 * This snippet can be used to check whether a provided value is an object
 * @param obj
 * @returns {boolean}
 */
const isObject = obj => obj === Object(obj);

/**
 * This snippet returns the distance between two points by calculating the Euclidean distance.
 * @param x0
 * @param y0
 * @param x1
 * @param y1
 * @returns {number}
 */
const distance = (x0, y0, x1, y1) => Math.hypot(x1 - x0, y1 - y0);

/**
 * Is email valid?
 * @param email
 * @returns {boolean}
 */
const emailIsValid = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

/**
 * Is browse tab in view?
 * @returns {boolean}
 */
const isBrowserTabInView = () => document.hidden;

/**
 * Truncate a number to a fixed decimal point
 * @param n
 * @param fixed
 * @returns {number}
 */
const toFixed = (n, fixed = 2) => ~~(Math.pow(10, fixed) * n) / Math.pow(10, fixed);

/**
 * Check if an element is currently in focus
 * @param el
 * @returns {boolean}
 */
const elementIsInFocus = (el) => (el === document.activeElement);

/**
 * Check if the current user has touch events supported
 * @returns {boolean|*}
 */
const touchSupported = () => ('ontouchstart' in window || window.DocumentTouch && document instanceof window.DocumentTouch);

/**
 * Left pad zeros
 * @param num
 * @param places
 * @returns {string}
 */
const padZeros = (num, places = 2) => String(num).padStart(places, '0');

const currentScrollPosition = () => document.documentElement.scrollTop || document.body.scrollTop;

const sprintf = (format, ...args) => {
  let i = 0;
  return format.replace(/%s/g, () => args[i++]);
}

const getOffset = el => {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY
  };
}

const isMobileMQ = () => window.matchMedia("(max-width: 767px)").matches;

export {
  closest, siblings, isPortrait, copyToClipboard, currentURL, delay, detectDeviceType, deviceIsDesktop, deviceIsMobile, isMobileMQ,
  elementContains, elementIsVisibleInViewport, isAnyPartOfElementInViewport, formToObject, getImages, nextAll, scrollPosition, addClasses, hasClass, hide,
  isBrowserTabFocused, on, off, scrollToTop, scrollToElement, scrollToY, currentScrollPosition, toggleClass, toggleClasses, triggerEvent,
  all, arrToCSV, arrayToHtmlList, arrayToUnorderedListString, bottomVisible, camelCase, capitalize, capitalizeEveryWord, compact, countOccurrences,
  deepFlatten, defer, difference, drop, dropRight, dropRightWhile, dropWhile, filterNonUnique, findKey,
  getStyle, insertAfter, insertBefore, intersection, is, isBoolean, isNil, isNilOrEmpty, isNumber, isObject, isString, isValidJSON,
  first, last, nodeListToArray, randomIntegerInRange, redirect, runPromisesInSeries, distance, findLast, emailIsValid,
  isBrowserTabInView, toFixed, elementIsInFocus, touchSupported, padZeros, sprintf, getOffset
};