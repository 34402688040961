import Swal from "sweetalert2";
import {Language} from "../../__GLOBAL__/GLOBAL";

const showErrorMessage = (message, title = Language.app.error, options = {}) => {
  Swal.fire({
    title: title, html: message, denyButtonText: "OK",
    showConfirmButton: false, showDenyButton: true, allowOutsideClick: false,
    customClass: { container: 'swal-err-msg' },
    ...options
  });
};

const showOkMessage = (message, title="OK", options = {}) => {
  Swal.fire({
    title: title, html: message, confirmButtonText: "OK",
    showConfirmButton: true, allowOutsideClick: false,
    customClass: { container: 'swal-ok-msg' },
    ...options
  });
};

export {showOkMessage, showErrorMessage}