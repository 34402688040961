/* global __webpack_public_path__ */
const { Page } = require('./__GLOBAL__/GLOBAL');

__webpack_public_path__ = Page.dist;

//load Common CSS
require('@fontsource/rubik/300.css');
require('@fontsource/rubik/400.css');
require('@fontsource/rubik/500.css');
require('@fontsource/rubik/700.css');
// require('bootstrap/dist/css/bootstrap-reboot.css');
// require('bootstrap/dist/css/bootstrap-grid.min.css');
// require('bootstrap/dist/css/bootstrap-utilities.css');
require('bootstrap/dist/css/bootstrap.min.css');
require('pretty-checkbox/dist/pretty-checkbox.min.css');
require('../stylesheets/fonts/icons/styles.css');
require('../stylesheets/main.scss');

//load JS plugins
import "core-js/stable";
import "regenerator-runtime/runtime";

//load modules
require('./modules/app');
try{ require(`./modules/${Page.MODULE}`); }
catch(e){} //silently ignore