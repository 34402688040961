import { polyfill as polyfill_scrollTo } from "seamless-scroll-polyfill";
import {
  on,
  scrollToElement
} from "./utils";

polyfill_scrollTo();

const $body = document.querySelector('body');
const $header_sticky = document.querySelector("#header");

const EventHandlers_Goto = () => {
  document.querySelectorAll("[data-goto-target]")
    .forEach(btn => {
      on(btn, "click", e => {
        e.preventDefault();
        const target = document.querySelector(btn.getAttribute("data-goto-target"));
        if(target){ scrollToElement(target); }
      });
    });
};

const stickyMenu = () => {
  const disable_sticky_menu = $body.classList.contains("disable-sticky-menu");
  if(disable_sticky_menu || !$header_sticky){return;}

  const _className = 'header-is-sticky';

  const {offsetTop: topDistance, clientHeight: height} = $header_sticky;

  const processMenuVisibility = () => {
    const distance = topDistance - window.pageYOffset;
    if(distance < -height && !$body.classList.contains(_className)){ $body.classList.add(_className); }
    else if(distance >= -height  && $body.classList.contains(_className)){ $body.classList.remove(_className); }
  };

  on(document, 'scroll', processMenuVisibility);

  processMenuVisibility();
};

const toggleOffcanvas = () => {
  const $btn = document.querySelector(".btn-toggle-offcanvas");
  if(!$btn){return;}

  on($btn, "click", e => {
    $btn.classList.toggle("is-active");
    $body.classList.toggle("offcanvas-is-active");
  });
}

EventHandlers_Goto();
toggleOffcanvas();
//stickyMenu();