import IMask from "imask";

const {AJAX_URL, Settings, Language} = require('../../__GLOBAL__/GLOBAL');

import {arrayToUnorderedListString, on, redirect} from "../utils";
import {showErrorMessage, showOkMessage} from "../utils/messages";

const axios = require('axios').default;
const Pristine = require('pristinejs');

const formValidator = pristine => {
  const is_valid = pristine.validate();
  if(!is_valid){
    showErrorMessage(
      `<p>${Language.app.forms.submit_error}</p>`.concat(
        arrayToUnorderedListString(pristine.getErrors().map(err => err.errors[0]))
      ),
    );
  }
  return is_valid;
}

const maskMobile = () => {
  const elems = document.querySelectorAll('[type="tel"]');

  const maskOptions = { mask: '000000000', lazy: true };

  elems.forEach(elem => {
    const mask = IMask(elem, maskOptions);
    on(elem, "focus", e => {
      mask.updateOptions({lazy: false});
      if(!mask.masked.rawInputValue){mask.value = '';}
    });
    on(elem, "blur", e => mask.updateOptions({ lazy: true }));
  });
}

const maskBirthdate = () => {
  const elems = document.querySelectorAll('[name="birthdate"]');

  const maskOptions = { mask: '00-00-0000', lazy: true };

  elems.forEach(elem => {
    const mask = IMask(elem, maskOptions);
    on(elem, "focus", e => {
      mask.updateOptions({lazy: false});
      if(!mask.masked.rawInputValue){mask.value = '';}
    });
    on(elem, "blur", e => mask.updateOptions({ lazy: true }));
  });
}

const maskPostalCode = () => {
  const elems = document.querySelectorAll('[name="postal_code"]');

  const maskOptions = { mask: '0000-000', lazy: true };

  elems.forEach(elem => {
    const mask = IMask(elem, maskOptions);
    on(elem, "focus", e => {
      mask.updateOptions({lazy: false});
      if(!mask.masked.rawInputValue){mask.value = '';}
    });
    on(elem, "blur", e => mask.updateOptions({ lazy: true }));
  });
}

const setButtonSubmiting = ($btn, state = true) => {
  if(state){
    $btn.setAttribute("disabled", "disabled");
    $btn.classList.add("m-progress");
  }
  else{
    $btn.removeAttribute("disabled");
    $btn.classList.remove("m-progress");
  }
};

const eventHandlers_Forms = () => document.querySelectorAll('.form').forEach(frm => eventHandlers_Form(frm));

const eventHandlers_Form = frm_selector => {
  const $frm = typeof frm_selector === 'string' ? document.querySelector(frm_selector) : frm_selector;
  if(!$frm){return;}
  const $btn = $frm.querySelector(".btn-submit");

  const pristine = new Pristine($frm);

  on($btn, "click", e => {
    e.preventDefault();

    //validate form
    if(!formValidator(pristine)){return;}

    //all ok, upload it!
    setButtonSubmiting($btn);

    //load form data
    const formData = new FormData($frm);
    axios
      .post(AJAX_URL, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(response => {
        const data = response.data, is_success = +(data?.success) === 1;
        if(is_success){ showOkMessage(data.message, data.title); $frm.reset(); }
        else{ showErrorMessage(data.message); }

        setButtonSubmiting($btn, false)
      })
      .catch(err => {
        console.log(err.toString());
        setButtonSubmiting($btn, false)
      });
  });
};

const allowOnlyNumbers = () => {
  document.querySelectorAll("[type='tel']")
    .forEach(el => {
      on(el, "keypress", e => {
        if([8, 46, 37, 38, 39, 40].includes(e.keyCode)){ return true; }
        if(isNaN(Number(e.key))){e.preventDefault(); return false;}
      });
    });
}

export { eventHandlers_Form, eventHandlers_Forms, setButtonSubmiting, formValidator, maskMobile, maskBirthdate, maskPostalCode, allowOnlyNumbers };